// .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
//     color: white !important; // Ensure it overrides default styles
// }

.login {
    min-height: 100vh;
    .login-box {
        background-color: #1a1a1a;
        border-radius: 25px;
    }
    .login-btn {
        height: 50px;
        font-size: 18px;
    }
}