// .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
//     color: white !important; // Ensure it overrides default styles
// }
// .MuiTypography-root.MuiTypography-body1.MuiListItemText-primary {
//     color: white !important;
// }
// .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
//     color: white !important;
// }
.MuiPaper-root.MuiDrawer-paper {
    background-color: #000 !important;
    div {
        justify-content: center;
    }
}

.MuiTableCell-head {
    background-color: #414141;
    font-weight: 600 !important;
    font-size: 16px !important;
}

.article-img-bg {
    background-color: #d4d4d4;
    height: 80px;
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    img {
        max-width: 100%;
        max-height: 100%;
    }
}

.article-view-img-bg {
    background-color: #d4d4d4;
    max-height: 300px;
    max-width: 300px;
    img {
        max-width: 100%;
        max-height: 280px;
    }
}

.upload-pdf-list {
    background-color: #000;
    border: 1px solid #414141;
}

.MuiTableFooter-root p {
    margin-bottom: 0 !important;
}

.MuiTableCell-body {
    padding: 6px 16px !important;
}

.MuiDrawer-paper hr {
    opacity: 1;
    border-color: #414141;
}